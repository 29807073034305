import React, { Component } from 'react';

class About_us extends Component {
    render() {
        return (
            <div>
                <h2>About_us</h2>
            </div>
        );
    }
}

export default About_us;