import { wait } from '@testing-library/dom';
import React, { Component, useState } from 'react';
import axios from 'axios';



class Member extends Component {

    constructor(props) {
        super(props);
        this.state = {
            members: []
        }
    }

    componentDidMount() {
        axios.get(`http://app.vietnamsos.net/`)
            .then(res => {
                const members = res.data;
                this.setState({ members });
            })
            .catch(error => console.log(error));
    }

    render() {
        return (
            <div>
                <h2>Member Page ddd</h2>
                <ul className={'member-list'}>
                    {this.state.members.map(member => <li>
                        <div className={'member-item'}>
                            <div>
                                <img src={member.img} />
                            </div>
                            <div>
                                <div><h4>{member.name}</h4></div>
                                <div>{member.phone}</div>
                                <div>{member.email}</div>
                            </div>
                        </div>

                    </li>)}
                </ul>
            </div>
        );
    }
}

export default Member;