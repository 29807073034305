import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div>
                <h2>Home Page â</h2>
            </div>
        );
    }
}

export default Home;