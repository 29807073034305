import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// THEM CAC COMPONENTS CUA MINH
import Header from '../header/header.js';
import Nav from '../nav/nav';
import Content from '../content/content.js';
import Footer from '../footer/footer.js';
import MemberApi from '../../api/member_api.js';




function App() {

  const [memberList, setMemberList] = useState([]);
  useEffect(() => {
    const fetchMemberList = async () => {
      try {
        const response = await MemberApi.getAll();
        //console.log(response);
        setMemberList(response.data);
      console.log(memberList);
      } catch (error) {
       console.log('loi get data');
      }
    }
    fetchMemberList();
  }, []);


  return (
    <Router>
      <div className='container-fluid'>
        <div className='col-lg-12'><Header /></div>
        <div className='col-lg-12'><Nav /></div>
        <div className='col-lg-12'><Content /></div>
        <div className='col-lg-12'><Footer /></div>
      </div>
    </Router>
  );
}

export default App;
